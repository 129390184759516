// @flow
/**
 * @type {Object}
 */
const allSidebarItemType = {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL"
};

export {
    allSidebarItemType
}
